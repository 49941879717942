import React from "react"
import Layout from "../components/layout"
import { Container, Row, Col, Form, FormGroup, Label, Input, Button, Alert } from 'reactstrap'
import { resetPassword } from '../services/team'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSpinner } from '@fortawesome/free-solid-svg-icons'

class Reset extends React.Component {

    constructor(props) {

        super(props);

        let urlData = props.location.search.replace("?", "").split("&")
        var params = urlData[0].split("=");

        this.state = {            
            hash: params[1],
            email: '',
            password: '',
            resettingPassword: false,
            passwordReset: false,
            passwordResetError: false
        };


    }

    async resetPassword() {
        this.setState({passwordReset: false, passwordResetError: false, resettingPassword: true})
        let result = await resetPassword(this.state.email, this.state.password, this.state.hash);

        if (result["error"] !== "") {
            this.setState({passwordReset: false, passwordResetError: true, resettingPassword: false})
        }
        else {
            this.setState({passwordReset: true, passwordResetError: false, resettingPassword: false})
        }
    }

    render() {
        

        return (

            <Layout>
                <div className="wrapper" style={{minHeight: "600px"}}>
                    <Container>
                        <Row>
                            <Col style={{ backgroundColor: "white", padding: "10px 20px", marginBottom: "25px", borderTop: "4px solid #343a40" }}>
                                
                                <h3>Reset Your Password</h3>
                                <div style={{marginBottom: "50px"}}>Enter your email address and a new password to change your password.</div>

                                <Form>
                                    <FormGroup row>
                                        <Label sm={2}>Email</Label>
                                        <Col sm={10}>
                                            <Input type="email" autoComplete="off" value={this.state.email} onChange={(e) => this.setState({ email: e.target.value })} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Label for="examplePassword" sm={2}>New Password</Label>
                                        <Col sm={10}>
                                            <Input type="text" autoComplete="off" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                                        </Col>
                                    </FormGroup>
                                    <FormGroup row>
                                        <Col className="text-center" style={{marginTop: "20px"}}>
                                            <Button onClick={() => this.resetPassword()}>Reset Password</Button>
                                        </Col>

                                    </FormGroup>
                                    
                                </Form>

                                {this.state.passwordResetError && <Alert color="danger">There was an error when trying to update your password.  You may need to send yourself a new password reset email.</Alert>}
                                {this.state.passwordReset && <Alert color="success">You successfully changed your password.  Go to the <a href="/softball-map">Softball Map</a> and log in to manage your teams</Alert>}
                                {this.state.resettingPassword && <Alert color="primary"><FontAwesomeIcon icon={faSpinner} spin /> Resetting your password...</Alert>}

                            </Col>
                        </Row>
                    </Container>
                </div>
            </Layout>
        )
    }


}

export default Reset

